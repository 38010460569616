if ('define' in window) {
define("discourse/theme-2/discourse/pre-initializers/theme-2-translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "theme-2-translations",
    initialize() {
      /* Translation data for theme 2 (en)*/
      const data = {
        "en": {}
      };
      for (let lang in data) {
        let cursor = I18n.translations;
        for (let key of [lang, "js", "theme_translations"]) {
          cursor = cursor[key] = cursor[key] || {};
        }
        cursor[2] = data[lang];
      }
    }
  };
});
}
